import { useRef, useEffect, useCallback } from 'react';

const useWasRendered = () => {
  let wasRendered = useRef(false);
  useEffect(() => {
    return () => (wasRendered.current = false);
  }, []);

  const setWasRendered = useCallback(() => {
    wasRendered.current = true;
  }, []);

  return { wasRendered, setWasRendered };
};

export default useWasRendered;
