import React from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { Icon } from 'antd';
import store from 'redux/store';

import es_ES from 'antd/es/locale/es_ES';
import en_US from 'antd/es/locale/en_US';
import pt_BR from 'antd/es/locale/pt_BR';



export const columnSort = column => {
  column = typeof column !== 'undefined' ? column : 'id';
  return {
    align: 'center',
    title: 'ID',
    key: column,
    dataIndex: column,
    sorter: (a, b) => a[column] - b[column],
    render: c => c
  };
};

export const filterColumn = (input, value) => {
  return input.indexOf(value) === 0;
};

export const sortObject = c => {
  return { sorter: (a, b) => a[c] - b[c] };
};

export const generateLinkTable = (record, path, key, reduxFunction = null, stateAccess = null) => {
  if (record.stateFilter) {
    record.stateFilter.isFilter = true;
  }
  const actualState = store.getState();
  return (
    <Link
      to={{
        pathname: path,
        state: {
          case: record
        }
      }}
      onClick={() => {
        if(reduxFunction && stateAccess && stateAccess.length){
          let aux = actualState;
          stateAccess.forEach(element => {
            aux = aux[element]
          });
          if(aux){
            store.dispatch(reduxFunction({
              ...aux,
              isFilter: true,
            }));
          }
        }
      }}
      style={record?.wasCancelled ? { color: 'red' } : {}}
    >
      {record[key]}
    </Link>
  );
};

export const generateUsersLinkTable = (record, path, text) => {
  return (
    <Link
      to={{
        pathname: path,
        state: {
          user: record,
          readOnly: true
        }
      }}
    >
      {text}
    </Link>
  );
};

export const generateNextToEnd = record => {
  if (record.registered) {
    return (
      <React.Fragment>
        {record.nextToEnd && (
          <Tooltip title="Próximo a expirar">
            {' '}
            <Icon type="clock-circle" />
          </Tooltip>
        )}
      </React.Fragment>
    );
  }
  // } else {
  //   return setFormatDateTime(record, 0);
  // }
};

export const generateRecordLink = (record, path, key, stateNameSpace) => {
  if (typeof stateNameSpace === 'undefined') stateNameSpace = 'record';

  return (
    <Link
      to={{
        pathname: path,
        state: {
          [stateNameSpace]: record
        }
      }}
    >
      {record[key]}
    </Link>
  );
};

export const cleanColumns = (columnsToClean, keyToEval, arr) => {
  const columnsClean = arr.filter(c => !columnsToClean.includes(c[keyToEval]));
  return columnsClean;
}

// export const FilterOpcions = data => {
//   console.log(data);
// };

export const getAntdLocale = (language) => {  
  let locale = 'en_US';   
  switch (language) {
    case 'es':
      locale = es_ES;
      break;
    case 'en':
      locale = en_US;
      break;
    case 'pt':
      locale = pt_BR;
      break;
    default:
      locale = en_US;
      break;
  }
  
  return locale;  
};  
