import React from 'react';
import moment from 'moment';
import { setRuleRequired } from '../utils/validations';
import { objectHasProperty } from '../utils/';
import { Select } from 'antd';
import { casesStatusEnum } from 'models/enums';
import MyLink from 'components/common/MyLink';
import { useTranslation } from 'react-i18next';
const { Option } = Select;

// import { dateFormat } from "../utils/constants";
const dateFormat = 'YYYY-MM-DD';

const { POR_ADJUDICAR } = casesStatusEnum;

export const layoutInputs = {
  sm: 12,
  md: 8,
  lg: 8,
  xl: 8
};

export const configValidFrom = previousForm => {
  let configuration = null;
  if (previousForm) {
    let date = moment(previousForm.validFrom);
    configuration = {
      rules: [setRuleRequired('Fecha de alta', 'object')],
      initialValue: moment(date).isValid() ? date : null
    };
  } else {
    configuration = {
      rules: [setRuleRequired('Fecha de alta', 'object')]
    };
  }
  return configuration;
};

export const configValidTo = previousForm => {
  let configuration = null;
  if (previousForm) {
    let date = moment(previousForm.validTo);
    configuration = {
      rules: [setRuleRequired('Fecha de baja', 'object')],
      initialValue: moment(date).isValid() ? date : null
    };
  } else {
    configuration = {
      rules: [setRuleRequired('Fecha de baja', 'object')]
    };
  }
  return configuration;
};

export const setDateNow = addDay => {
  let tomorrow = moment().add(1, 'days');
  if (addDay) {
    return tomorrow.toISOString();
  }
  return moment().toISOString();
};

export const configValidCreated = previousForm => {
  return {
    rules: [setRuleRequired('Fecha de constitución', 'object')],
    initialValue: previousForm.created ? moment(previousForm.created, dateFormat) : null
  };
};

export const configValidDate = (previousForm, inputLabel, inputName, isRequired) => {
  const inputRequired = typeof isRequired !== 'undefined' ? isRequired : true;
  let initial = null;
  if (previousForm && previousForm[inputName]) {
    initial = moment(previousForm[inputName]);
    initial = moment(initial, dateFormat);
  } //? moment(previousForm[inputName], dateFormat) : null;
  //let date =

  if (inputRequired) {
    return {
      rules: [setRuleRequired(inputLabel, 'object')],
      initialValue: initial
    };
  } else {
    return {
      initialValue: initial
    };
  }
};

export const configValidBorn = previousForm => {
  return {
    rules: [setRuleRequired('Fecha de constitución', 'object')],
    initialValue: previousForm.bornDate ? moment(previousForm.bornDate, dateFormat) : null
  };
};

export const loadingSelect = {
  hasFeedback: true,
  validateStatus: 'validating'
};

export const createListOptions = (objectData, keyData, id, name) => {
  if (objectHasProperty(objectData, keyData) && objectData[keyData].length > 0) {
    return objectData[keyData].map(e => (
      <Option key={e[id]} value={e[id]}>
        {e[name]}
      </Option>
    ));
  } else {
    return [];
  }
};

export const setInitialDate = date => {
  date = moment(date);
  return moment(date).isValid() ? date : null;
};

export const setInitialHour = hour => {
  return moment(hour, 'HH:mm:ss');
};

export const saveAndAction = (formToState, values, step, lastStep, action) => {
  formToState(values, step, lastStep);
  if (!lastStep) action();
};

export const alphaNumericMask = () => {
  return '[abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ0123456789]';
};

export const letterMask = () => {
  return '[abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ]';
};

export const chunkTitle = (title, lengthAllowed) => {
  let setTitle = {};
  if (title && lengthAllowed && title.length > lengthAllowed) {
    setTitle = {
      title: title
    };
  }
  return setTitle;
};

export const filterOption = (input, option) =>
  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

const triggerParent = trigger => trigger.parentNode;

export const getPopupContainer = triggerParent;

export const getCalendarContainer = triggerParent;

export const extraButtonAwardings = params => {
  const { location, caseStatusId, caseId, label = "Adjudicar" } = params;

  if (location?.state?.fromAwardingsSearch) {
    if (POR_ADJUDICAR === caseStatusId) {
      let objURL = {
        pathname: `/awardings/add/${caseId}/detail`,
        state: { fromAwardingsSearch: true }
      };
      let search = location && location.handleSearch ? location.handleSearch : false;

      return (
        <MyLink
          type="button"
          label={label}
          to={objURL}
          objWithCallbacks={{ handleSearch: search }}
        />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};
