import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import autoStore from './redux/store';
import App from './components/App/';
import { initialConfigurations } from '../src/utils';
import './assets/css/fonts.css';
import './assets/css/main.css';
import { ConfigProvider } from 'antd';
import * as serviceWorker from './serviceWorker';
import './utils/i18n';
import { getAntdLocale } from 'helpers/helperTables';
import i18next from 'i18next';

// let store = generateStore();
initialConfigurations();
const i18nLocale = getAntdLocale(i18next.language);

let WithStore = () => (
  <Provider store={autoStore}>
    <BrowserRouter>
      <ConfigProvider locale={i18nLocale}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>
);
ReactDOM.render(<WithStore />, document.getElementById('root'));

serviceWorker.unregister();
